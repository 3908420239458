@keyframes greyIn {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
  }
}

@keyframes greyOut {
  from {
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(500%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(500%);
  }
}

@mixin animation($value) {
  animation: $value 0.5s ease-in-out;
}

.bg-dialog-blur {
  z-index: 999;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;

  &.grey-in {
    @include animation(greyIn);
  }
  &.grey-out {
    @include animation(greyOut);
  }
}

.card-wrapper {
  position: absolute;
  top: 3rem;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  &.centered {
    align-items: center;
  }
  &.justified {
    justify-content: center;
  }
}

.card {
  background-color: white;
  border: 1px white;
  box-shadow: 0px 4px 6px rgb(155, 155, 155);
  border-radius: 7px;
  margin: 10px;
  padding: 3px;
  font-size: 17px;
  min-width: 15rem;
  max-width: calc(100% - 1rem);
  height: 100%;
  max-height: calc(100% - 9rem);
  display: flex;
  flex-direction: column;

  &.slide-down {
    @include animation(slideDown);
  }
  &.slide-up {
    @include animation(slideUp);
  }

  .header {
    max-height: 3rem;
    height: 3rem;
    display: flex;
    font-size: 20px;
    color: #000;
    .title {
      font-weight: 500;
      padding: 5px 10px;
      width: calc(100% - 30px);
    }
    .close-btn {
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
    }
  }

  .body {
    flex: 1;
    height: calc(100% - 4rem);
    overflow-y: auto;
    margin: 5px 10px;
    max-width: calc(100%);
    color: #000;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 5px 10px;
    max-height: 3rem;
  }

  &.bottom {
    border-radius: 7px 7px 0px 0px;
  }
}
