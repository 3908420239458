$input-border-color: #c5c5c5;
$input-border-radius: 2px;
$vacation: #969696;
$not-working: #e0e0e0;
$excused-absence: #bdbdbd;
$warning: #ff6c00;
$themes: (
  light: (
    backgroundColor: white,
    backgroundColorHover: #e8e8e8,
    cardBackground: white,
    textColor: #000000,
    inputBackgroundColor: white,
    inputBackgroundColorHover: #f5f5f5,
    inputBorderColor: $input-border-color,
    inputBorderColorLighter: #ececec,
    inputBorderColorHover: $input-border-color,
    h1TextColor: #0083ff,
    subtitleTextColor: #424242,
    boxShadow: #dcdcdc,
    checkboxBorderHover: black,
    invoiceLabelColor: #676767,
    userInitials: #29b6f6,
    userInitialsHover: #009ae0,
    themeBlue: #0083ff,
    btnGreenBackgroundColor: #23d2b3,
    btnGreenBackgroundColorHover: #1ebea2,
    error: #ff007f,
    errorHover: #ff007f,
    devOpsIconBacground: #0072ff,
    badgeNotificationColor: #ff8400,
    today: #90caf9,
    remote: #ffeb3b,
    remoteHover: #fbde00,
    loader: #dddddd,
    loaderBar: #ababab,
    activateBtnBackground: #23a0d2,
    activateBtnBackgroundHover: #166788,
    lock: #ff00fa,
    todayProgressBar: #489aff,
    weeklyProgressBar: #29b6f6,
    monthProgressBar: #ffe000,
    excelIconColor: #019e73,
    checkBoxFullHoverBackground: #1ebea2,
    checkBoxEmptyHoverBackground: #f5f5f5,
    checkBoxFullHoverBorder: #1ebea2,
    checkBoxEmptyHoverBorder: $input-border-color,
    checkBoxFullBackground: #23d2b3,
    switchBackgroundOff: #ccc,
    switchBackgroundOffHover: #b7b7b7,
    switchBackgroundOn: #40a2ff,
    switchBackgroundOnHover: #2f99ff,
    dayOff: #d0d0d0,
    dailyEntryOnMobileBackground: #f3f3f3,
    todayHistory: #ede7f6,
    todayHistoryHover: #d1c4e9,
    progressBarBackground: #f3f3f3,
    activeDay: #7e57c2,
  ),
  dark: (
    backgroundColor: #222222,
    backgroundColorHover: #383838,
    cardBackground: #292929,
    textColor: #e2e2e2,
    inputBackgroundColor: #404040,
    inputBackgroundColorHover: #292929,
    inputBorderColor: #525252,
    inputBorderColorLighter: #525252,
    inputBorderColorHover: #525252,
    subtitleTextColor: white,
    h1TextColor: white,
    boxShadow: black,
    progressBarBackground: #404040,
    checkboxBorderHover: white,
    invoiceLabelColor: white,
    userInitials: #777777,
    userInitialsHover: #929292,
    themeBlue: #00acff,
    btnGreenBackgroundColor: #80cbc4,
    btnGreenBackgroundColorHover: #4db6ac,
    error: #f48fb1,
    errorHover: #f06292,
    devOpsIconBacground: #777777,
    badgeNotificationColor: #f15f79,
    today: #00acff,
    todayHover: #33353c,
    remote: #fff176,
    remoteHover: #f5d252,
    activateBtnBackground: #0083ff,
    activateBtnBackgroundHover: #166788,
    lock: #f48fb1,
    todayProgressBar: #7e57c2,
    weeklyProgressBar: #5c6bc0,
    monthProgressBar: #0288d1,
    excelIconColor: #81c784,
    checkBoxFullBackground: #777676,
    checkBoxFullHoverBackground: #505050,
    checkBoxEmptyHoverBackground: #484848,
    checkBoxFullHoverBorder: #525252,
    checkBoxEmptyHoverBorder: #525252,
    switchBackgroundOff: #656565,
    switchBackgroundOffHover: #797979,
    switchBackgroundOn: #00acff,
    switchBackgroundOnHover: #0094dc,
    dayOff: #6b6b6b,
    dailyEntryOnMobileBackground: #383838,
    todayHistory: #3e4048,
    todayHistoryHover: #33353c,
    activeDay: #85b9d8,
  ),
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
