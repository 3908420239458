:root {
  --primary-color: #7c4dff;
  --light-select-color: #e5defb;
  --lighter-select-color: #f3efff;
  --dark-primary-color: #6a3ee3;
  --secondary-color: #f7e75d;
  --light-secondary-color: #fcf194;
  --lighter-secondary-color: #fff9c7;
  --dark-secondary-color: #e6d64e;
  --red-primary-color: #f44336;
  --dark-red-color: #d32f2f;
  --green-primary-color: #4caf50;
  --dark-green-color: #388e3c;
  --yellow-primary-color: #eddb37;
  --dark-yellow-color: #fbc02d;
}

.purple-spinner .p-progress-spinner-circle {
  animation: purple-progress-spinner-dash 1.5s ease-in-out infinite,
    purple-progress-spinner-color 2s ease-in-out infinite;
}

@keyframes purple-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary-color);
  }
  50% {
    stroke: var(--dark-primary-color);
  }
}

.p-button {
  background-color: var(--primary-color);
  font-weight: 700;
}
.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--dark-primary-color);
  color: white;
}

.delete-button.p-button {
  background-color: var(--red-primary-color);
  font-weight: 700;
  width: 6rem;
  border: solid 1px var(--dark-red-color);
}
.delete-button.p-button:enabled:hover,
.delete-button.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--dark-red-color);
  color: white;
  border-color: var(--dark-red-color);
}

.green-action-button.p-button {
  background-color: var(--green-primary-color);
  font-weight: 700;
  width: 6rem;
}
.green-action-button.p-button:enabled:hover,
.green-action-button.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--dark-green-color);
  color: white;
}

.yellow-button.p-button {
  background-color: var(--yellow-primary-color);
  font-weight: 700;
  border: solid 1px var(--dark-yellow-color);
}
.yellow-button.p-button:enabled:hover,
.yellow-button.p-button:not(button):not(a):not(.p-disabled):hover {
  background: var(--dark-yellow-color);
  color: white;
}

.p-inputtext {
  border-radius: 5px;
}

.p-dialog-title {
  margin-left: 0.5rem;
}

.p-fieldset-content {
  padding: 0 !important;
}
.mobile-work-instances-table.p-datatable > .p-datatable-header {
  padding: 3px;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0;
  width: 50%;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.p-selectbutton .p-button:not(button):not(a):not(.p-disabled):hover {
  background: "var(--dark-primary-color)";
  border-color: var(--dark-primary-color);
}
.sidebar-mobile-nav.p-sidebar .p-sidebar-header {
  justify-content: center;
}

.sidebar-mobile-nav.p-sidebar {
  background-color: rgb(240, 238, 238);
}

.mobile-table.p-datatable .p-datatable-header {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.mobile-table.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.mobile-dialog.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 0.8rem 0.8rem 0.8rem;
}

.two-option-select-button.p-selectbutton .p-button {
  width: 50%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.progress-bar .p-progressbar-value.p-progressbar-value-animate {
  transition: width 0.1s linear;
}

.fade-in {
  animation: fade-in 0.5s ease-in-out normal;
}
.fade-in.back {
  opacity: 0;
  animation: fade-in 0.5s ease-in-out reverse;
}

.qr-codes-chips.p-chips .p-chips-multiple-container .p-chips-token {
  margin: 2px;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: var(--light-select-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-color);
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--primary-color);
  border: 0px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background-color: var(--dark-primary-color);
  border-color: var(--dark-primary-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--dark-primary-color) !important;
  box-shadow: 0px 0px 0px var(--dark-primary-color) !important;
}

.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: var(--lighter-select-color);
}

.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:focus {
  outline: 0px;
  outline-offset: 0px;
}
