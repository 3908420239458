@import "./_vars";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.w-0 {
  width: 0%;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* CALENDAR */

.calendar-info {
  width: 100%;
  height: 55px;
}

.calendar-body {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  user-select: none;
}

.pagination {
  .btn {
    line-height: 1rem;
    margin-left: 3px;
    vertical-align: top;
    border-radius: 3px;
    background: #404040;
    border: 1px solid #525252;
    color: white;
    &:hover,
    &:focus {
      background: #292929;
      color: white;
      outline: none;
      box-shadow: none;
    }
  }
}

.cell {
  padding: 0.5rem 0.5rem;
  @include themify($themes) {
    border: 0.5px solid themed("inputBorderColor");
  }
}

.selectable-cell {
  &:hover {
    cursor: pointer;
    background-color: var(--lighter-secondary-color);
  }
}

.not-selectable-cell {
  @include themify($themes) {
    background-color: themed("dayOff");
  }
}

.day {
  margin-bottom: -1px;
}

.day-name {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  color: rgb(82, 80, 80);
  background-color: var(--light-select-color);
}

.day-5 {
  width: 20%;
}

.day-7 {
  width: 14.28%;
}

.hide-weekend {
  display: none;
}

.today {
  background-color: var(--light-select-color);

  &:hover {
    background-color: var(--light-secondary-color);
  }
}

.active,
.active.today {
  z-index: 1;
  background-color: var(--light-secondary-color);
  &:hover {
    background-color: var(--secondary-color);
  }
}

.p-button-wide {
  width: 14rem;
}

.previous-month-day {
  background: #f5f5f5;
  cursor: default !important;
  pointer-events: none;
}

.lock {
  font-weight: 700;
  margin-left: 10px;

  @include themify($themes) {
    color: themed("error");
  }
}

@media (max-width: 992px) {
  .cell {
    padding: 0.5rem 0.3rem;
  }
}

.entry {
  font-size: 0.85rem;
  overflow: hidden;
  margin-bottom: 3px;
  padding: 0.2rem 0.3rem;
  @include themify($themes) {
    color: themed("textColor");
    background: themed("inputBackgroundColor");
    border: 1px solid themed("inputBorderColor");
  }
  box-shadow: 0 1px 4px 0 rgba(83, 78, 93, 0.08);
}

.circle-with-count {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  vertical-align: middle;
  border-radius: 100%;
}

.circle {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  vertical-align: middle;
  border-radius: 100%;
}

.duration {
  font-size: 0.75rem;
}

a {
  cursor: pointer;
}

input {
  font-size: 1rem;
}

body {
  background-color: white;

  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("backgroundColor");
  }

  .input-theme,
  .calendar-theme input {
    font-size: 1rem;
    width: 100%;
    padding: 0.429em;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    &:focus {
      @include themify($themes) {
        border: 1px solid themed("themeBlue");
        outline: none;
      }
    }

    @include themify($themes) {
      background: themed("inputBackgroundColor");
      border: 1px solid themed("inputBorderColor");
      color: themed("textColor");
    }
  }

  .input-theme {
    &:read-only {
      border: none;
      outline: none;
    }
  }

  .input-theme.ng-invalid.ng-dirty,
  .input-theme.ng-invalid.ng-touched,
  app-time-input.ng-dirty.ng-invalid.ng-touched .time-input.ng-touched,
  .pagination.invalid button {
    @include themify($themes) {
      border: 1px solid themed("error") !important;
    }
  }

  .calendar-theme .ui-calendar {
    display: block;

    input {
      width: 100%;
    }
  }

  .btn {
    font-family: Lato;
    &.ui-button.ui-button-text-only .ui-button-text {
      padding: 0;
    }
  }

  @mixin marginAuto($position, $name) {
    .m#{$position}-auto {
      margin-#{$name}: auto;
    }
  }
  @mixin margin($position, $size, $name) {
    .m#{$position}-#{$size} {
      margin-#{$name}: #{$size}rem;
    }
  }

  @include marginAuto("t", "top");

  @include marginAuto("b", "bottom");

  @include marginAuto("l", "left");

  @include marginAuto("r", "right");

  @include margin("t", 0, "top");

  @include margin("b", 3, "bottom");

  @mixin btnFilledHoverAndDisabled {
    &:focus {
      box-shadow: none !important;
    }

    &:disabled {
      cursor: not-allowed;
      color: #6f6f6f;
      border: 1px solid #cecece;
      background: #cecece;
    }
  }

  .btn-save-filled {
    @include themify($themes) {
      color: themed("backgroundColor");
      background: themed("btnGreenBackgroundColor");
      border: 1px solid themed("btnGreenBackgroundColor");
    }

    &:hover,
    &:enabled:hover {
      @include themify($themes) {
        color: themed("backgroundColor");
        background: themed("btnGreenBackgroundColorHover");
        border: 1px solid themed("btnGreenBackgroundColorHover");
      }
    }
    @include btnFilledHoverAndDisabled;
  }

  .btn-activate-filled {
    @include themify($themes) {
      color: themed("backgroundColor");
      background: themed("activateBtnBackground");
      border: 1px solid themed("activateBtnBackground");
    }

    &:hover,
    &:enabled:hover {
      @include themify($themes) {
        color: themed("backgroundColor");
        background: themed("activateBtnBackgroundHover");
        border: 1px solid themed("activateBtnBackgroundHover");
      }
    }
    @include btnFilledHoverAndDisabled;
  }

  .btn-cancel-filled {
    @include themify($themes) {
      color: themed("backgroundColor");
      background: themed("error");
      border: 1px solid themed("error");
    }

    &:hover,
    &:enabled:hover {
      @include themify($themes) {
        color: themed("backgroundColor");
        background: themed("errorHover");
        border: 1px solid themed("errorHover");
      }
    }
    @include btnFilledHoverAndDisabled;
  }

  .settings-button,
  .settings-button:enabled:active,
  .settings-button:enabled {
    @include themify($themes) {
      background: themed("inputBackgroundColor");
      border: 1px solid themed("inputBorderColor");
      color: themed("textColor");
    }

    &:hover,
    &:focus {
      color: #202020;
      outline: none;
      background-color: #d2d2d2;
      box-shadow: none;
    }
  }
}

h1 {
  font-size: 1.2rem;
  @include themify($themes) {
    color: themed("h1TextColor");
  }
}

h2 {
  font-size: 1rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.flex-column {
  flex-direction: column;
}

.section {
  margin: 1rem 0;
  padding: 0.5rem 0;
}

.text-right {
  text-align: right;
}

.border-right {
  @include themify($themes) {
    border-right: 1px solid themed("inputBorderColor");
  }
}

.border-bottom {
  @include themify($themes) {
    border-bottom: 1px solid themed("inputBorderColor") !important;
  }
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

@mixin alignItems($name) {
  .align-items-#{$name} {
    align-items: #{$name} !important;
  }
}

@include alignItems("center");
@include alignItems("flex-end");

@mixin justifyContent($name) {
  .justify-content-#{$name} {
    justify-content: #{$name};
  }
}

@include justifyContent("center");
@include justifyContent("flex-end");
@include justifyContent("space-between");

@mixin btn($name, $font-size) {
  .btn-#{$name} {
    font-size: $font-size;

    @if $name == "save" {
      @include themify($themes) {
        color: themed("btnGreenBackgroundColor");
      }
    }

    @if $name == "trash" {
      @include themify($themes) {
        color: themed("error");
      }
    }

    @if $name == "back" {
      @include themify($themes) {
        color: #313131;
      }
    }

    @if $name == "excel" {
      @include themify($themes) {
        color: themed("excelIconColor");
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: #cccccc;
    }
  }
}

@include btn("trash", 1.3rem);
@include btn("save", 1.3rem);
@include btn("back", 1.3rem);
@include btn("edit", 1.3rem);
@include btn("excel", 2rem);

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.font-bold {
  font-weight: bold;
}

.noselect {
  user-select: none;
}

body .listbox-theme {
  .ui-listbox {
    border: none !important;
  }
  .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
    @include themify($themes) {
      color: 1px solid themed("textColor");
    }
    background-color: transparent;
  }
}

input:-webkit-autofill {
  @include themify($themes) {
    -webkit-box-shadow: 0 0 0 30px themed("inputBackgroundColor") inset !important;
    color: themed("textColor") !important;
    -webkit-text-fill-color: themed("textColor") !important;
    &:hover,
    &:focus,
    &:active {
      -webkit-box-shadow: 0 0 0 30px themed("inputBackgroundColor") inset !important;
      color: themed("textColor") !important;
    }
  }
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

.error {
  font-size: 13px;
  min-height: 21px;
  padding: 5px 0 0;
  @include themify($themes) {
    color: themed("error");
  }
}

.loader-container {
  position: relative;
}

.warning {
  color: $warning;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;

  .calendar-theme {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    .ui-calendar,
    input {
      width: 100%;
    }
  }
}

.input-group-prepend {
  display: flex;
  margin-right: -2px;
}

.input-group-append {
  display: flex;
  margin-left: -2px;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.daily-entries {
  .section {
    margin-top: 1.4rem;
    padding: 1rem;
    @include themify($themes) {
      border: 1px solid themed("activeDay");
    }
    background: transparent;
  }
}

.p-col-fixed {
  max-width: 100%; //prevent cutting text on mobile
}

.search {
  font-size: 1.2rem;
  text-align: center;
  color: #484848;
}

.search__icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 1.5rem;
  color: #cccccc;
}

.previous-month-day .day {
  display: none;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .p-md-auto {
    flex-basis: 0;
    flex-grow: 1;
  }
}

@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 14px;
  }
}

.fade-in {
  -webkit-animation: fadeIn ease-out 0.3s;
  -moz-animation: fadeIn ease-out 0.3s;
  -ms-animation: fadeIn ease-out 0.3s;
  -o-animation: fadeIn ease-out 0.3s;
  animation: fadeIn ease-out 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 29px;
}

/* region custom section */
section {
  margin: 5px 0px;
  width: 100%;

  &.height-full {
    height: calc(100% - 1rem);

    .section-content {
      height: calc(100% - 33px);
      display: flex;
      flex-direction: column;

      .content-row {
        &.map {
          flex-grow: 1;
        }
      }
    }
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  .section-header {
    display: block;
    width: max-content;
    background-color: #7c4dff;
    color: white;
    font-size: larger;
    padding: 5px 10px 5px 10px;
    border-radius: 9px 9px 0px 0px;

    i {
      font-size: 1.25rem;
      padding-right: 5px;
    }
  }

  .sub-section-header {
    display: block;
    font-size: small;
    padding: 3px 10px 5px 10px;
    border-radius: 0px 9px 0px 0px;
    background-color: #fbc02d;
    width: max-content;
  }

  .anomally {
    font-size: small;
    padding: 4px 13px;
    border-radius: 2rem;
    background-color: #c82e48;
    color: white;
    width: max-content;
  }

  .section-content {
    border-left: 1px solid rgba(150, 150, 150, 0.3);
    border-top: 1px solid rgba(150, 150, 150, 0.3);
    border-radius: 0px 9px 0px 0px;
    box-shadow: 3px 2px 2px 1px rgba(150, 150, 150, 0.2);
    margin-right: 5px;
    padding: 10px;

    .content-row {
      display: flex;
      padding: 0px 5px 4px;
      &.thin {
        width: 50%;
      }

      .table-header-container {
        &.border {
          border-top: 1px solid rgba(150, 150, 150, 0.3);
        }

        margin: 0px -15px;
        padding: 5px 0px 0px 15px;
        width: calc(100% + 15px);
        display: block;

        > .column-three {
          display: flex;
          width: 100%;
          padding-left: 10px;
          align-items: center;

          &.status {
            max-width: 70px;
            margin-left: -10px;
          }
        }
      }

      .table-rows-container {
        display: flex;
        width: 100%;

        input {
          width: calc(100% - 10px);
        }

        &:not(:nth-child(2)) {
          margin: 10px 0px;
        }

        &:nth-child(2) {
          margin-bottom: 10px;
        }

        .column-three {
          width: 100%;

          &.status {
            margin-left: -10px;
            max-width: 70px;
            display: flex;
            height: 32px;
            align-items: center;
            justify-content: center;

            img {
              width: 1.25em;
            }
          }
        }
      }

      .checkbox-container {
        padding: 5px;
        display: flex;
        width: 100%;

        p-checkbox {
          padding-top: 22px;
          width: 50%;
        }

        &.wide {
          width: 200%;
        }

        &.thin {
          width: 50%;
        }
      }

      .input-wrapper {
        margin: 0px 5px;
        padding: 10px 0px;
        width: 100%;

        .add-address-btn-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 10px;
          height: 2rem;
        }

        p-checkbox.checkbox-top {
          align-items: start;
        }

        &.wide {
          width: 203%;
        }

        textarea {
          width: 100%;
          resize: none;
          height: 6rem;
        }

        .label {
          color: gray;
          margin-left: 5px;
          margin-bottom: 3px;
        }

        & > div {
          margin-top: 3px;
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efefef;
  margin-bottom: 2px;
  border-radius: 0 0.75rem 0.75rem 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216);
  border-radius: 0 0 0.75rem 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

/* end region custom section */

/* region custom progress bar */

.progressbar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  background-color: #dee2e6;
}

.progressPercent {
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #dee2e6;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}

/* end region custom progress bar */

// start region custom prime react switch input

.p-inputswitch.p-component.p-inputswitch-checked .p-inputswitch-slider {
  &:hover {
    background-color: #6434e9 !important;
  }
  background-color: var(--primary-color) !important;
}

// end region custom prime react switch input

// start region custom prime react DataTable

.p-datatable-flex-scrollable > .p-datatable-wrapper {
  border-radius: 0px 0px 10px 10px !important;
}

// end region custom prime react DataTable

.tags-table.p-datatable .p-datatable-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.p-tabview-panels {
  padding: 0px !important;
  height: calc(100% - 50px);
  overflow: auto;
  background: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent !important;
}
.p-tabview .p-tabview-nav {
  background: transparent !important;
}

.blur-container {
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: 0.3s all ease;
}

.image-wrapper {
  position: relative;
  margin: 0 0.25rem 0 0.25rem;
}

.image-wrapper:hover {
  z-index: 1;
  cursor: pointer;
  & .image-loupe {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);

    .image-background {
      background-color: #20202056;
      display: flex;
      width: 100%;
      height: calc(100% - 0.2rem);
      align-items: center;
      justify-content: center;
    }
  }
}

.image-loupe {
  display: none;
}

.p-chips .p-chips-multiple-container {
  width: 100%;
}

.p-datatable .p-datatable-header {
  border-radius: 0.75rem 0.75rem 0 0;
}

.cell-elipsis {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.edit-time-input .p-inputnumber-input {
  width: 55px;
  text-align: center;
  border-width: 0px;
}

.edit-time-input .p-inputnumber-input:focus {
  box-shadow: 0 0 0 0 !important;
}

.p-inputnumber-input {
  width: 100% !important;
}
